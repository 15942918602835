import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import VendorUserList from "../pages/Vendor/VendorUserList";
import VendorUserCreate from "../pages/Vendor/VendorUserCreate";
import ApproveOrRejectView from "../pages/PoManagement/ApproveOrRejectView";
import VendorUserView from "../pages/Vendor/VendorUserView";
import PoHeadersList from "../pages/PO Information/PoHeadersList";
import PoHeadersView from "../pages/PO Information/PoHeadersView";
import UserProfile from "../pages/Authentication/UserProfile";

const createRoute = (path, component) => ({ path, component });

const routes = [
    createRoute('/dashboard', <Dashboard />),
    createRoute("/approveOrReject/:id", <ApproveOrRejectView />),
    createRoute('/vendor-user-list', <VendorUserList />),
    createRoute('/vendor-user-create/:id?', <VendorUserCreate />),
    createRoute('/vendor-user-view/:id', <VendorUserView />),
    createRoute('/po-headers-list', <PoHeadersList />),
    createRoute('/po-headers-view/:id', <PoHeadersView/>),
    createRoute('/po-headers-view', <PoHeadersView/>),
    createRoute('/profile-view/:id', <UserProfile/>),
    { path: '/', exact: true, component: <Navigate to='/dashboard' /> },
];

const paginationRoute = routes
    .filter(route => ["/vendor-user-list","/po-headers-list"].includes(route.path))
    .map(route => route.path);

const searchRoute = routes
    .filter((route) =>
        ['/vendor-user-list'].includes(route.path)
    )
    .map((route) => route.path);    

export { routes, paginationRoute, searchRoute }; 