import { useState, useEffect, useCallback } from "react";
import { getRecords } from "../../services/api.service";

const useFetchData = (initialPayload, page, pageSize,url,searchFields) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const getData = useCallback(async () => {
        try {
            setIsLoading(true);
            let payload = {
                ...initialPayload,
            };

            if (searchKeyword) {
                payload.search = {
                    field_name: searchFields || [],
                    searchKeyword: searchKeyword,
                };
            } else {
                payload.pagination = {
                    page: page + 1,
                    pageSize: pageSize,
                }
            }

            console.log('payload initialPayload', initialPayload);
            console.log('payload', payload);
            
            const responseData = await getRecords(url,payload);
            if (responseData.code === 1) {
                setTotalCount(responseData.count);
                const sortedData = responseData.data
                    .map((element, index) => ({
                        ...element,
                        index: page * pageSize + (index + 1),
                    }))
                    .sort((a, b) => b.index - a.index);
                setData(sortedData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [page, pageSize, searchKeyword, initialPayload]);

    useEffect(() => {
        getData();
    }, [getData]);

    return {
        data,
        isLoading,
        setSearchKeyword,
        totalCount,
    };
};

export default useFetchData;