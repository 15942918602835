import React, { useState } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { saveRecords, getRecords } from "../../services/api.service";
import { toast } from "react-toastify";
import loginLogo from '../../assets/images/logo.jpg';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";

const ForgetPasswordPage = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtpField, setShowOtpField] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const navigate = useNavigate();

    const { message, forgetError, loading } = useSelector(state => state.Forget);

    // handle email submission
    const handleValidSubmit = async (event, values) => {
        // dispatch(forgetUser(values, navigate));
        const emailPayload = {
            username: values.useremail,
        };
        console.log("email Payload:",emailPayload);
        try {
            const responseData = await saveRecords(
              "/vendors/auth/login/forgot-password-request",
              emailPayload
            );
            if (responseData.code === 1) {
              toast.success(responseData.message);
              localStorage.setItem("email_data", JSON.stringify(responseData));
              setEmail(values.useremail);
              setShowOtpField(true);
            } else {
              toast.error(responseData.message);
              setShowOtpField(false);
            }
          } catch (error) {
            toast.error(error.message);
            console.error("Error===>", error);
            setShowOtpField(false);
          }
    };

    // handle OTP submission
    const handleOtpSubmit = async (event, values) => {
        event.preventDefault(); // Prevent default form submission if applicable
        console.log("clicked");
    
        // Retrieve and parse the email data from localStorage
        const storedUserData = localStorage.getItem("email_data");
        const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
    
        if (parsedUserData && parsedUserData.data && parsedUserData.data.id) {
            console.log("users id:", parsedUserData.data.id);

            const otpPayload = {
                "modelName": "vendor_users",
                "whereCondition": {
                    id: parsedUserData.data.id, // Correctly access the 'id'
                    otp: values.otp
                }
            }
    
            try {
                const responseData = await getRecords(
                    "/common/getRecords",
                    otpPayload
                );
                if (responseData.data && responseData.data.length > 0) {
                    toast.success("OTP Verified Successfully");
                    setIsOtpVerified(true);
                } else {
                    toast.error("Please Enter Valid OTP");
                    setIsOtpVerified(false);
                }
            } catch (error) {
                toast.error(error.message);
                console.error("Error===>", error);
                setIsOtpVerified(false);
            }
        } else {
            console.error("No valid user ID found in localStorage.");
        }
    };
    
    

    // handle new password submission
    const handleNewPasswordSubmit = async (event, values) => {
        const storedUserData = localStorage.getItem("email_data");
        const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
    
        if (parsedUserData && parsedUserData.data && parsedUserData.data.id) {
            console.log("users id:", parsedUserData.data.id);
            const newPasswordPayload = {
                id: parsedUserData.data.id, // Correctly access the 'id'
                newPassword: values.newpassword,
                confirmPassword: values.confirmnewpassword
            };
            try {
                const responseData = await getRecords(
                  "/vendors/auth/login/change-password",
                  newPasswordPayload
                );
                if (responseData.code === 1) {
                  toast.success(responseData.message);
                  navigate('/login');
                } else {
                  toast.error(responseData.message);
                }
              } catch (error) {
                toast.error(error.message);
                console.error("Error===>", error);
            }
        }
    };

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className='custom-col-wrapper'>
                                <div className='custom-authentication-bg'></div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="custom-authentication-page-content authentication-page-content p-4 min-vh-100 xs-min-vh-50">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="">
                                                <Link
                                                    to='/'
                                                    className='mb-8 d-block'
                                                >
                                                    <img
                                                        src={loginLogo}
                                                        alt='Grind Master Logo'
                                                        height='60'
                                                    />
                                                </Link>
                                                    <h3 className="font-size-18 mt-4">Welcome Vendor,</h3>
                                                    <h4 className="text-muted">Reset your password to Vendor Portal.</h4>
                                                    <p className="text-muted"></p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    {forgetError ? (
                                                        <Alert color="danger" className="mb-4">
                                                            {forgetError}
                                                        </Alert>
                                                    ) : null}

                                                    {message ? (
                                                        <Alert color="success" className="mb-4">
                                                            {message}
                                                        </Alert>
                                                    ) : null}

                                                    <AvForm
                                                        className="form-horizontal"
                                                        onValidSubmit={
                                                            isOtpVerified
                                                                ? handleNewPasswordSubmit // Handle new password submission
                                                                : showOtpField
                                                                ? handleOtpSubmit // Handle OTP submission
                                                                : handleValidSubmit // Handle email submission
                                                        }
                                                    >
                                                        {/* Email Field */}
                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="useremail">Email</Label>
                                                            <AvField
                                                                name="useremail"
                                                                value={email}
                                                                type="email"
                                                                validate={{
                                                                    required: { value: true, errorMessage: "Email is required" },
                                                                    email: { value: true, errorMessage: "Invalid email format" },
                                                                }}
                                                                className="form-control"
                                                                id="useremail"
                                                                placeholder="Enter email"
                                                                disabled={showOtpField || isOtpVerified} // Disable after submission
                                                            />
                                                        </div>

                                                        {/* OTP Field */}
                                                        {showOtpField && !isOtpVerified && (
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="otp">OTP</Label>
                                                                <AvField
                                                                    name="otp"
                                                                    value={otp}
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: true, errorMessage: "OTP is required" },
                                                                        maxLength: { value: 4, errorMessage: "OTP must be 4 digits" },
                                                                        minLength: { value: 4, errorMessage: "OTP must be 4 digits" },
                                                                    }}
                                                                    className="form-control"
                                                                    id="otp"
                                                                    placeholder="Enter OTP"
                                                                />
                                                            </div>
                                                        )}

                                                        {/* New Password Fields */}
                                                        {isOtpVerified && (
                                                            <>
                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="newpassword">New Password</Label>
                                                                    <AvField
                                                                        name="newpassword"
                                                                        type="password"
                                                                        validate={{
                                                                            required: { value: true, errorMessage: "New password is required" },
                                                                            minLength: { value: 6, errorMessage: "Password must be at least 6 characters" },
                                                                        }}
                                                                        className="form-control"
                                                                        id="newpassword"
                                                                        placeholder="Enter new password"
                                                                    />
                                                                </div>

                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="confirmnewpassword">Confirm New Password</Label>
                                                                    <AvField
                                                                        name="confirmnewpassword"
                                                                        type="password"
                                                                        validate={{
                                                                            required: { value: true, errorMessage: "Confirm password is required" },
                                                                            match: { value: "newpassword", errorMessage: "Passwords must match" },
                                                                        }}
                                                                        className="form-control"
                                                                        id="confirmnewpassword"
                                                                        placeholder="Confirm new password"
                                                                    />
                                                                </div>
                                                            </>
                                                        )}

                                                        {/* Submit Buttons */}
                                                        <div className="mt-4 text-center">
                                                            {!isOtpVerified ? (
                                                                <Button
                                                                    color="primary"
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                >
                                                                    {showOtpField ? "Submit OTP" : loading ? "Loading..." : "Reset"}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    color="primary"
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                >
                                                                    {loading ? "Updating..." : "Update Password"}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </AvForm>

                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Back to Login{" "}
                                                        <Link to="/" className="fw-medium text-primary">
                                                            Log in
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ForgetPasswordPage;