import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { getRecords, saveRecords } from '../../services/api.service';
import { toast } from 'react-toastify';

export default function PoHeadersView() {
    const navigate = useNavigate();
    let { id } = useParams();
    const [poData, setPoData] = useState([]);
    const [poLogData, setPoLogData] = useState([]);
    const [poLinesData, setPoLinesData] = useState([]);
    const [buyerGroup, setBuyerGroup] = useState([]);
    const [loadingData, setLoadingData] = useState(!!id);

    useEffect(() => {
        if (id) {
            getData(id);
            getPoLogData(id);
            getPoLinesData(id);
        }
    }, [id]);

    const getData = async (id) => {
        try {
            let payload = {
                modelName: 'po_managements',
                whereCondition: {
                    id: id,
                },
                relations: [
                    {
                        module: 'warehouse_masters',
                        moduleAs: 'warehouse_masters',
                    },
                    {
                        module: 'vendor_masters',
                        moduleAs: 'vendor_masters',
                    },
                ],
            };
            const responseData = await getRecords(
                '/common/getRecords',
                payload
            );
            if (responseData.data && responseData.data.length > 0) {
                const data = responseData.data[0];
                setPoData(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingData(false);
        }
    };

    const getPoLogData = async (id) => {
        try {
            let payload = {
                modelName: 'po_logs',
                whereCondition: {
                    po_id_149: id,
                },
                relations: [
                    {
                        module: 'po_status',
                        moduleAs: 'po_status',
                    },
                ],
            };
            const responseData = await getRecords(
                '/common/getRecords',
                payload
            );
            if (responseData.data && responseData.data.length > 0) {
                const data = responseData.data;
                setPoLogData(data);
                console.log('data for Po Logs===>', data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingData(false);
        }
    };

    const getPoLinesData = async (id) => {
        try {
            let payload = {
                modelName: 'po_details',
                whereCondition: {
                    po_id_126: id,
                },
                relations: [
                    {
                        module: 'vendor_item_masters',
                        moduleAs: 'vendor_item_masters',
                    },
                ],
            };
            const responseData = await getRecords(
                '/common/getRecords',
                payload
            );
            if (responseData.data && responseData.data.length > 0) {
                const data = responseData.data;
                setPoLinesData(data);
                console.log('data for Po Lines===>', data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingData(false);
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className='page-content'>
            <Container fluid>
                <div className='tabs-animation'>
                    <Card>
                        <CardBody>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3>View</h3>
                                <button
                                    className='btn btn-primary'
                                    onClick={(e) =>
                                        navigate('/po-headers-list')
                                    }
                                >
                                    Back
                                </button>
                            </div>

                            {/* PO Headers Table with Legend */}
                            <fieldset
                                style={{
                                    border: '1px solid #ddd',
                                    padding: '15px',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <legend
                                    style={{
                                        fontSize: '1.5rem',
                                        color: '#333',
                                    }}
                                >
                                    PO Headers
                                </legend>
                                <div className='row'>
                                    <div className='col'>
                                        <table className='table table-striped table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            PO Number
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_number_72 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>PO Date</strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_date_73
                                                            ? formatDate(
                                                                  poData.po_date_73
                                                              )
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Vendor Code
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.vendor_masters
                                                            ?.vendor_code_44 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Vendor Name
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.vendor_name_76 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Site</strong>
                                                    </td>
                                                    <td>
                                                        {poData.site_78 || '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Warehouse
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData
                                                            .warehouse_masters
                                                            ?.warehouse_name_21 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Location
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.location_80 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Buyer Group
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.buyer_group_81 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            PO Owner
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_owner_82 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='col'>
                                        <table className='table table-striped table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            PO Indentor
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_indentor_83 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Indentor Mobile No
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.indentor_mobile_no_84 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            TDS Applicable
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.tds_applicable_85 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            TDS Group
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.tds_group_86 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Mode of Delivery
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.mode_of_delivery_88 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Payment Terms
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.payment_terms_89 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Method of Payment
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.method_of_payment_90 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Cost Center
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.cost_center_91 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Department
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.department_92 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Project</strong>
                                                    </td>
                                                    <td>
                                                        {poData.project_93 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='col'>
                                        <table className='table table-striped table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Delivery Date
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.delivery_date_87
                                                            ? formatDate(
                                                                  poData.delivery_date_87
                                                              )
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Min. GRN Date
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.minimum_grn_date_94
                                                            ? formatDate(
                                                                  poData.minimum_grn_date_94
                                                              )
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Committed Date 1
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.delivery_date_1_95
                                                            ? formatDate(
                                                                  poData.delivery_date_1_95
                                                              )
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Committed Date 2
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.delivery_date_2_96
                                                            ? formatDate(
                                                                  poData.delivery_date_2_96
                                                              )
                                                            : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            PO Approval
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_status_125 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            PO Status
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {poData.po_status_microsoft_130 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Remark</strong>
                                                    </td>
                                                    <td>
                                                        {poData.remark_132 ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset
                                style={{
                                    border: '1px solid #ddd',
                                    padding: '15px',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <legend
                                    style={{
                                        fontSize: '1.5rem',
                                        color: '#333',
                                    }}
                                >
                                    PO Lines
                                </legend>
                                <div className='table-responsive'>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Other Charges</th>
                                                <th>Assessable Value</th>
                                                <th>Net Amount</th>
                                                <th>HSN</th>
                                                <th>SAC</th>
                                                <th>PO Line Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {poLinesData &&
                                            poLinesData.length > 0 ? (
                                                poLinesData.map(
                                                    (log, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {log.item_code_113 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log
                                                                    .vendor_item_masters
                                                                    ?.item_name_39 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log
                                                                    .vendor_item_masters
                                                                    ?.unit_41 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.quantity_115 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.price_116 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.other_charges_117 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.assessable_value_119 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.net_amount_118 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.hsn_120 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.sac_121 ||
                                                                    '-'}
                                                            </td>
                                                            <td>
                                                                {log.po_line_status_156 ||
                                                                    '-'}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan='12'
                                                        className='text-center'
                                                    >
                                                        No data available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>

                            {/* PO Log Section with Legend */}
                            <fieldset
                                style={{
                                    border: '1px solid #ddd',
                                    padding: '15px',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                                className='mt-4'
                            >
                                <legend
                                    style={{
                                        fontSize: '1.5rem',
                                        color: '#333',
                                    }}
                                >
                                    PO Log
                                </legend>
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Updated By</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Remark</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poLogData && poLogData.length > 0 ? (
                                            poLogData.map((log, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {log.type_151 === 1
                                                            ? log.user_master
                                                                  ?.employee_master
                                                                ? `${
                                                                      log
                                                                          .user_master
                                                                          .employee_master
                                                                          .first_name
                                                                  } ${
                                                                      log
                                                                          .user_master
                                                                          .employee_master
                                                                          .middle_name ||
                                                                      ''
                                                                  } ${
                                                                      log
                                                                          .user_master
                                                                          .employee_master
                                                                          .last_name
                                                                  }`
                                                                : '-'
                                                            : log.type_151 === 2
                                                            ? log.vendor_user
                                                                  ?.name_66 ||
                                                              '-'
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        {log.type_151 || '-'}
                                                    </td>
                                                    <td>
                                                        {log.po_status?.name ||
                                                            '-'}
                                                    </td>
                                                    <td>
                                                        {log.remark_150 || '-'}
                                                    </td>
                                                    <td>
                                                        {log.date_155
                                                            ? formatDate(
                                                                  log.date_155
                                                              )
                                                            : '-'}
                                                    </td>{' '}
                                                    {/* Format date if needed */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan='6'
                                                    className='text-center'
                                                >
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </fieldset>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </div>
    );
}
