import React, { useState, useEffect } from "react";
import { getRecords, dropdownData, saveRecords } from "../../services/api.service.js";
import { validate } from "../validations/Validate.js";
import useDependentDropdown from "../../custom/Utils/UseDependentDropdownUtils.js";
import useFileUpload from "../../custom/Utils/UseFileUploadUtils.js";

const useCreateForm = (id, initialPayload, initialFormData, validateType, dependentDropdownData) => {
  const [formData, setFormData] = useState(initialFormData);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDropOption, setSelectedDropOption] = useState("");
  const [selectedMultiDropOptions, setSelectedMultiDropOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [multiModuleValidation, setMultiModuleValidation] = useState({});
  const [dependentValue, setDependentValue] = useState(null);

  // dependent dropdown code
  const { getDropdownData, getDependentDropdownData, handleSelectChange, selectedDropdown, selectedDropdownOptions, selectedDropdownDependent, selectedDropdownOptionsDependent } =
    useDependentDropdown(dependentDropdownData, dependentValue, validateType, setSelectedDropOption, setFormErrors, setFormData, setLoadingData)

  // file upload code
  const { handleFileUpload, handleDownloadMediaClick, handleRemoveMediaClick, uploadImage, uploadMedia, isDownloadingImage, isDownloadingMedia, removeDownloadIcons,
  } = useFileUpload(id, formData, setFormData);

  useEffect(() => {
    if (id) {
      getData(id);
    }
    getDropdownData()
  }, [id]);

  const mediaList = [
    {
      options: [
        { label: "Audio", value: "Audio" },
        { label: "Video", value: "Video" },
        { label: "Document", value: "Document" },
      ],
    },
  ];

  const getData = async (id) => {
    try {
      let payload = {
        ...initialPayload,
      };

      if (id) {
        payload.whereCondition = { id: id };
      }

      const responseData = await getRecords('/common/getRecords', payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          setFormData({ inputData: responseData.data[0], });
          getDropdownData(responseData.data[0]?.[dependentDropdownData[0]?.dropdownDataValue]);
          getDependentDropdownData(responseData.data[0]?.[dependentDropdownData[1]?.dropdownDataValue]);
          setDependentValue(responseData.data[0]?.[dependentDropdownData[0]?.dropdownDataValue]);
        }
      }
      setLoadingData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
    }));
  };

  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const fieldError = validate(validateType, {
      [name]: value,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError[name] || null,
    }));

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
      },
    }));
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedOption((prev) => (prev === value ? "" : value));
  };

  const handleMultiSelectChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption : [];
    const fieldError = validate(validateType, {
      multiMedia: selectedValue,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      multiMedia: fieldError.multiMedia || "",
    }));

    if (
      selectedOption &&
      selectedOption.some((option) => option.value === "select-all")
    ) {
      const selectedOptionFilterdOption = mediaList[0].options.filter(
        (option) => option.value !== "select-all"
      );
      setSelectedMultiDropOptions(selectedOptionFilterdOption);
      setFormData((prev) => ({
        ...prev,
        inputData: {
          ...prev.inputData,
          multiMedia: selectedOptionFilterdOption,
        },
      }));
    } else {
      setSelectedMultiDropOptions(selectedOption);
      setFormData((prev) => ({
        ...prev,
        inputData: {
          ...prev.inputData,
          multiMedia: selectedOption,
        },
      }));
    }
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const selectionStart = target.selectionStart;

    // Prevent deleting or editing colons
    if (
      ["Backspace", "Delete"].includes(key) &&
      (selectionStart === 2 || selectionStart === 5)
    ) {
      e.preventDefault();
      return;
    }

    // Restrict input to digits only, except for navigation keys
    if (
      !/[0-9]/.test(key) &&
      !["ArrowLeft", "ArrowRight", "Tab", "Home", "End"].includes(key)
    ) {
      e.preventDefault();
    }
  };

  const handleTimeChange = (e) => {
    const input = e.target;
    let value = input.value;

    // Enforce HH:mm:ss format (prevent adding/removing colons)
    let formattedValue = value
      .replace(/[^0-9]/g, "") // Remove non-numeric characters
      .substring(0, 6); // Limit to 6 characters (HHmmss)

    // Insert colons at appropriate positions
    if (formattedValue.length >= 2) {
      formattedValue = `${formattedValue.slice(0, 2)}:${formattedValue.slice(
        2
      )}`;
    }
    if (formattedValue.length >= 5) {
      formattedValue = `${formattedValue.slice(0, 5)}:${formattedValue.slice(
        5
      )}`;
    }

    // Update state only if the input matches HH:mm:ss format
    const fieldError = validate(validateType, {
      duration: formattedValue,
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      duration: fieldError.duration || "",
    }));
    setFormData((prev) => ({
      ...prev,
      inputData: {
        ...prev.inputData,
        duration: formattedValue,
      },
    }));
  };

  const handleMultimodeChange = (moduleName, updatedRows) => {
    // Step 1: Validate the updated rows
    const fieldError =
      validate(validateType, {
        [moduleName]: updatedRows,
      }) || {};

    const newFieldErrors = parseMultiModule(fieldError, moduleName) || {};

    setMultiModuleValidation((prevValidation) => {
      const currentValidation = Array.isArray(prevValidation[moduleName])
        ? prevValidation[moduleName]
        : [];

      const updatedValidation = updatedRows.map((row, index) => {
        const newErrors = newFieldErrors[index] || {};

        const updatedErrors = { ...currentValidation[index] };
        Object.keys(updatedErrors).forEach((key) => {
          if (!newErrors[key]) {
            delete updatedErrors[key];
          }
        });

        return {
          ...updatedErrors,
          ...newErrors,
        };
      });

      return {
        ...prevValidation,
        [moduleName]: updatedValidation,
      };
    });

    setFormErrors((prevErrors) => {
      const hasErrors = Object.keys(newFieldErrors).length > 0;
      return {
        ...prevErrors,
        [moduleName]: hasErrors ? newFieldErrors : "",
      };
    });

    // Step 5: Update form data state with the new values
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [moduleName]: updatedRows, // Update input data
      },
    }));
  };

  const parseMultiModule = (formErrors, multiModuleName) => {
    const multiModule = [];

    // Parse errors into a structured format for each row
    Object.keys(formErrors).forEach((key) => {
      const match =
        key.match(new RegExp(`${multiModuleName}\\[(\\d+)]\\.(\\w+)`)) || [];

      if (match) {
        const index = parseInt(match[1], 10); // Get index from regex match
        const property = match[2]; // Get property name (e.g., "firstName")

        if (!multiModule[index]) {
          multiModule[index] = {}; // Initialize row if not exists
        }

        multiModule[index][property] = formErrors[key]; // Set error for the property
      }
    });

    return multiModule;
  };

  const handleMultiModuleValidation = (validationErrors, multiModuleNames) => {
    const multiModuleValidationResults = {};

    multiModuleNames.forEach((moduleName) => {
      const moduleValidationErrors = {};

      for (const key in validationErrors) {
        if (key.startsWith(moduleName)) {
          const regex = new RegExp(`${moduleName}\\[(\\d+)]\\.(\\w+)`);
          const [, index, field] = key.match(regex) || [];
          if (index) {
            moduleValidationErrors[index] = {
              ...(moduleValidationErrors[index] || {}),
              [field]: validationErrors[key],
            };
          }
        }
      }

      multiModuleValidationResults[moduleName] = moduleValidationErrors;
    });

    return multiModuleValidationResults;
  };

  return {
    formData,
    setFormData,
    handleTextChange,
    onInputChange,
    handleRadioChange,
    handleSelectChange,
    handleMultiSelectChange,
    handleKeyDown,
    handleTimeChange,
    handleFileUpload,
    handleMultimodeChange,
    selectedOption,
    selectedDropOption,
    selectedMultiDropOptions,
    formErrors,
    uploadImage,
    setLoadingData,
    loadingData,
    setFormErrors,
    setFormErrors,
    multiModuleValidation,
    setMultiModuleValidation,
    handleMultiModuleValidation,
    selectedDropdown,
    selectedDropdownOptions,
    selectedDropdownDependent,
    selectedDropdownOptionsDependent,
    handleDownloadMediaClick, handleRemoveMediaClick,  uploadMedia, isDownloadingImage, isDownloadingMedia, removeDownloadIcons,
  };
};

export default useCreateForm;
