import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import {
  formContentContainer,
  loaderOverlay,
} from "../../Style/loaderOverly";
import { AvForm } from "availity-reactstrap-validation";
import { LoaderCreatePage } from "../../pages/Loader/Loader";

const CustomForm = ({
  title,
  loadingData,
  onFormSubmit,
  children,
  handleBackClick,
  id,
}) => {
  const breadcrumbItems = [];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb title={title} breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={onFormSubmit}
                      className="needs-validation">
                      {children}
                      <div className="mt-3">
                        <Button
                          color="success"
                          type="submit"
                          className="waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </div>
                    </AvForm>
                    {/* Loader Overlay */}
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomForm;
