import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, Form } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import { useLocation } from "react-router-dom";
import { paginationRoute, searchRoute } from "../../routes/routeConfig";
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);


  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}
    </React.Fragment>
  );
};

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  onPageSizeChange,
  onSearchInputData,
  pageIndexProp,
  nextToPage,
  lastToPage,
  previousToPage,
  previousFirstToPage,
  totalCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const [searchInput, setSearchInput] = useState("");
  const totalPages = Math.ceil(totalCount / pageSize);
  const pageOptionList = Array.from({ length: totalPages }, (_, i) => i);
  const customCanNextPage = pageIndexProp < totalPages - 1;
  const customCanPreviousPage = pageIndexProp > 0;

  const location = useLocation()
  const isPaginationRoute = paginationRoute.includes(location.pathname);

  const isSearchableRoute = searchRoute.includes(location.pathname);

  useEffect(() => {
    if (isPaginationRoute) {
      onPageSizeChange(pageSize);
    }
  }, [pageSize, onPageSizeChange, isPaginationRoute]);

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };
  
  //code might be required in the future

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
  // serchInput is a global state
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    onSearchInputData(value);
  };
  return (
      <Fragment>
          <Row className='mb-2'>
              <Col
                  md={customPageSizeOptions ? 2 : 1}
                  className='d-flex align-items-center'
              >
                  Show{' '}
                  <select
                      className='form-select mx-2'
                      style={{ width: '150px' }}
                      value={pageSize}
                      onChange={onChangeInSelect}
                  >
                      {[10, 25, 50, 75, 100].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                              {pageSize}
                          </option>
                      ))}{' '}
                  </select>{' '}
                  entries
              </Col>
              {isGlobalFilter && (
                  <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      isJobListGlobalFilter={isJobListGlobalFilter}
                  />
              )}
              {isAddOptions && (
                  <Col sm='7'>
                      <div className='text-sm-end'>
                          <Button
                              type='button'
                              color='success'
                              className='btn-rounded  mb-2 me-2'
                              onClick={handleOrderClicks}
                          >
                              <i className='mdi mdi-plus me-1' />
                              Add New Order
                          </Button>
                      </div>
                  </Col>
              )}
              {isAddUserList && (
                  <Col sm='7'>
                      <div className='text-sm-end'>
                          <Button
                              type='button'
                              color='primary'
                              className='btn mb-2 me-2'
                              onClick={handleUserClick}
                          >
                              <i className='mdi mdi-plus-circle-outline me-1' />
                              Create New User
                          </Button>
                      </div>
                  </Col>
              )}
              {isAddCustList && (
                  <Col sm='7'>
                      <div className='text-sm-end'>
                          <Button
                              type='button'
                              color='success'
                              className='btn-rounded mb-2 me-2'
                              onClick={handleCustomerClick}
                          >
                              <i className='mdi mdi-plus me-1' />
                              New Customers
                          </Button>
                      </div>
                  </Col>
              )}
              {isSearchableRoute && (
                  <Col className='ms-auto' md='3'>
                      <Form className='app-search d-flex d-lg-inline mt-0'>
                          <div className='position-relative'>
                              <Input
                                  type='search'
                                  className='form-control'
                                  placeholder='Search'
                                  value={searchInput}
                                  onChange={handleSearchChange}
                              />
                              <span className='ri-search-line'></span>
                          </div>
                      </Form>
                  </Col>
              )}
          </Row>

          <div className='table-responsive react-table'>
              <Table bordered hover {...getTableProps()} className={className}>
                  <thead className='table-light table-nowrap'>
                      {headerGroups.map((headerGroup) => (
                          <tr
                              key={headerGroup.id}
                              {...headerGroup.getHeaderGroupProps()}
                          >
                              {headerGroup.headers.map((column) => (
                                  <th key={column.id}>
                                      <div
                                          className='mb-2'
                                          {...column.getSortByToggleProps()}
                                      >
                                          {column.render('Header')}
                                          {generateSortingIndicator(column)}
                                      </div>
                                      <Filter column={column} />
                                  </th>
                              ))}
                          </tr>
                      ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                          prepareRow(row);
                          return (
                              <Fragment key={row.getRowProps().key}>
                                  <tr>
                                      {row.cells.map((cell) => {
                                          return (
                                              <td
                                                  key={cell.id}
                                                  {...cell.getCellProps()}
                                              >
                                                  {cell.render('Cell')}
                                              </td>
                                          );
                                      })}
                                  </tr>
                              </Fragment>
                          );
                      })}
                  </tbody>
              </Table>
          </div>

          <Row className='justify-content-md-end justify-content-center align-items-center'>
              <Col className='col-md-auto'>
                  <div className='d-flex gap-1'>
                      <Button
                          color='primary'
                          onClick={
                              isPaginationRoute
                                  ? previousFirstToPage
                                  : () => gotoPage(0)
                          }
                          disabled={
                              isPaginationRoute
                                  ? !customCanPreviousPage
                                  : !canPreviousPage
                          }
                      >
                          {'<<'}
                      </Button>
                      <Button
                          color='primary'
                          onClick={
                              isPaginationRoute ? previousToPage : previousPage
                          }
                          disabled={
                              isPaginationRoute
                                  ? !customCanPreviousPage
                                  : !canPreviousPage
                          }
                      >
                          {'<'}
                      </Button>
                  </div>
              </Col>
              <Col className='col-md-auto d-none d-md-block'>
                  Page{' '}
                  <strong>
                      {isPaginationRoute ? pageIndexProp + 1 : pageIndex + 1} of{' '}
                      {isPaginationRoute
                          ? pageOptionList.length
                          : pageOptions.length}
                  </strong>
              </Col>
              <Col className='col-md-auto'>
                  <div className='d-flex gap-1'>
                      <Button
                          color='primary'
                          onClick={isPaginationRoute ? nextToPage : nextPage}
                          disabled={
                              isPaginationRoute
                                  ? !customCanNextPage
                                  : !canNextPage
                          }
                      >
                          {'>'}
                      </Button>
                      <Button
                          color='primary'
                          onClick={
                              isPaginationRoute
                                  ? lastToPage
                                  : () => gotoPage(pageCount - 1)
                          }
                          disabled={
                              isPaginationRoute
                                  ? !customCanNextPage
                                  : !canNextPage
                          }
                      >
                          {'>>'}
                      </Button>
                  </div>
              </Col>
          </Row>
      </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
