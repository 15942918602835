import {
  required,
  isEmail,
  isNumber,
  maxLength,
  minLength,
  isAlphabet,
  isPhoneNumber,
  isPassword,
  isChecked,
  isURL,
  isValidDate,
  isValidTime,
} from "./Ruls";

export const validate = (formName, formData) => {
  const errors = {};

  const validationRules = {
      EnvironmentCreate: {
          environment: [required, isAlphabet, minLength(3)],
          email: [required, minLength(5), isEmail],
          age: [required, isNumber],
          firstName: [required, minLength(1), maxLength(10)],
          phoneNumber: [isPhoneNumber],
          password: [isPassword],
          is_active: [isChecked],
          active: [isChecked],
          url: [isURL],
          date: [required, isValidDate],
          duration: [isValidTime, required],
          media: [required],
          multiMedia: [required],
          multiModule: {
              type: [required],
              firstName: [required, isAlphabet, minLength(1), maxLength(10)],
              lastName: [required, isAlphabet, minLength(1), maxLength(10)],
              age: [required, isNumber],
          },
          secondMultiModule: {
              type1: [required],
              firstName1: [required, isAlphabet, minLength(1), maxLength(10)],
              lastName1: [required, isAlphabet, minLength(1), maxLength(10)],
              age1: [required, isNumber],
          },
      },
      CountryCreate: {
          country_name: [required, isAlphabet],
          is_active: [isChecked],
      },
      VendorCreate: {
          email_id_68: [required, minLength(5), isEmail],
          username_127: [required, minLength(3), maxLength(15)],
          name_66: [required, minLength(3), maxLength(15), isAlphabet],
          mobile_no_67: [required, isPhoneNumber],
          password_69: [required],
          //is_active_157: [isChecked],
      },
  };

  const rulesForForm = validationRules[formName];

  if (!rulesForForm) {
    return errors; // Return empty if no rules exist for the form
  }

  const processValidation = (rules, data, parentKey = "") => {
    for (const field in rules) {
      const fieldRules = rules[field];
      const fieldValue = data[field];
      const fullKey = parentKey ? `${parentKey}.${field}` : field;

      if (Array.isArray(fieldRules)) {
        // Validate flat fields
        for (const rule of fieldRules) {
          const errorMessage = rule(fieldValue, field);
          if (errorMessage) {
            errors[fullKey] = errorMessage;
            break;
          }
        }
      } else if (Array.isArray(fieldValue)) {
        // Special case for arrays like multiModule
        fieldValue.forEach((row, index) => {
          // Process each object in the array
          processValidation(fieldRules, row, `${fullKey}[${index}]`);
        });
      } else if (typeof fieldRules === "object") {
        // Recurse into nested objects
        processValidation(fieldRules, fieldValue || {}, fullKey);
      }
    }
  };

  processValidation(rulesForForm, formData);

  return errors;
};
