
export const listPageData = () => {
    const vendorId = localStorage.getItem('vendorId');
    const payload = {
        modelName: 'po_managements',
        whereCondition: {
            vendor_id_74: vendorId,
        },
        relations: [
            {
                module: 'warehouse_masters',
                moduleAs: 'warehouse_masters',
            },
            {
                module: 'vendor_masters',
                moduleAs: 'vendor_masters',
            },
            {
                module: 'po_status',
                moduleAs: 'po_status',
            },
        ],
    };
    const navigatePage = {
        view: '/po-headers-view',
    };
    const headerName = 'PO Headers';

    return { payload, navigatePage, headerName };
};
