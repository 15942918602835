import React, { useState, useEffect } from "react";
import { Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Table } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getRecords } from "../../services/api.service";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const breadcrumbItems = [
        { title: "Grind Master Vendor Portal", link: "/" },
        { title: "Dashboard", link: "#" },
    ];
    const navigate = useNavigate();

    const [open, setOpen] = useState();
    const [userData, setUserData] = useState();
    const [poData, setPoData] = useState({
        "PENDING PO": [], 
        "DECLINED BY GMMPL": [], 
        "APPROVED BY GMMPL": [], 
        "REVISED PO": [],
        "WIP": [],
        "REQUESTED FOR QA": [], 
        "QA APPROVED": [],
        "QA REJECTED": [],
    });

    const toggle = (id) => {
        setOpen(open === id ? '' : id);
    };

    const fetchData = async (category, payload) => {
        try {
            const response = await getRecords('/common/getRecords', payload);
            const data = await response.data;
            console.log("data===>", data);
            
            setPoData(prevData => ({
                ...prevData,
                [category]: data
            }));
        } catch (error) {
            console.error(`Failed to fetch data for ${category}:`, error);
        }
    };

    useEffect(() => {
        const localData = localStorage.getItem("authUser");
        const parsedData = JSON.parse(localData);

        const payloads = {
            "PENDING PO": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 0
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "DECLINED BY GMMPL": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 4
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "APPROVED BY GMMPL": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 3
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "REVISED PO": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 2
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "WIP": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 1
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "REQUESTED FOR QA": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 9
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "QA APPROVED": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 10
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            },
            "QA REJECTED": {
                "modelName": "po_managements",
                "whereCondition": {
                    "vendor_id_74": parsedData.vendor_id_65,
                    "po_status_125": 11
                },
                "pagination": {
                    "page": 1,
                    "pageSize": 100
                },
                "relations": [
                    {
                        "module": "warehouse_masters",
                        "moduleAs": "warehouse_masters"
                    },
                    {
                        "module": "po_status",
                        "moduleAs": "po_status"
                    }
                ]
            }
        };

        Object.entries(payloads).forEach(([category, payload]) => {
            fetchData(category, payload);
        });
    }, []);

    const handleView = (id) => {
        navigate(`/approveOrReject/${id}`);
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
    
        // Check for ISO 8601 format (contains "T")
        if (dateString.includes("T")) {
            const date = new Date(dateString);
            if (isNaN(date)) return "-"; // Invalid ISO date
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    
        // Check for YYYY-MM-DD format
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
            const [year, month, day] = dateString.split("-").map(Number);
            const date = new Date(year, month - 1, day); // Month is 0-indexed
            if (isNaN(date)) return "-";
            return `${String(day).padStart(2, "0")}-${String(month).padStart(2, "0")}-${year}`;
        }
    
        // Handle DD-MM-YYYY format
        const parts = dateString.split("-");
        if (parts.length === 3) {
            const [day, month, year] = parts.map(Number);
            const date = new Date(year, month - 1, day); // Month is 0-indexed
            if (isNaN(date)) return "-"; // Invalid date
            return dateString; // Already in DD-MM-YYYY format
        }
    
        return "-"; // If format is not recognized
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
            </Container>

            <Container fluid className="mt-2">
                <Accordion open={open} toggle={toggle}>
                    {Object.entries(poData).map(([category, data], index) => (
                        <AccordionItem
                            key={index}
                            className="mb-3"
                            style={{
                                borderRadius: "10px",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow
                            }}
                        >
                    
                            <AccordionHeader
                                targetId={String(index + 1)}
                                style={{
                                    borderRadius: "10px",
                                }}
                            >
                                <b>{category} ({data.length})</b>
                            </AccordionHeader>
                            <AccordionBody
                                accordionId={String(index + 1)}
                                style={{ borderRadius: "10px", overflowY: "auto", maxHeight: "450px"}}
                            >
                                <Table bordered striped hover responsive>
                                    <thead className="table-header-bold fw-bold">
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>PO Number</th>
                                            <th>PO Date</th>
                                            <th>Site</th>
                                            <th>Warehouse</th>
                                            <th>Buyer Group</th>
                                            <th>Delivery Date</th>
                                            <th>Min. GRN Date</th>
                                            <th>PO Approval</th>
                                            <th>PO Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? data.map((po, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{po.po_number_72 || "-"}</td>
                                                <td>{formatDate(po.po_date_73) || "-"}</td>
                                                <td>{po.site_78 || "-"}</td>
                                                <td>{po.warehouse_masters?.warehouse_name_21|| "-"}</td>
                                                <td>{po.buyer_group_81 || "-"}</td>
                                                <td>{formatDate(po.delivery_date_87) || "-"}</td>
                                                <td>{formatDate(po.minimum_grn_date_94) || "-"}</td>
                                                <td>{po.po_status?.name || "-"}</td>
                                                <td>{po.po_status_microsoft_130|| "-"}</td>
                                                <td>
                                                    <i
                                                        className="ri-eye-fill text-primary cursor-pointer"
                                                        style={{ fontSize: '20px', cursor: 'pointer' }} // Increase the size of the icon
                                                        onClick={() => handleView(po.id)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="11" className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </AccordionBody>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Container>
        </div>
    );
};

export default Dashboard;