const env = process.env.REACT_APP_ENV;

let apiUrl;
let imageUrl = process.env.REACT_APP_IMAGE_URL;

if (env == "testing") {
  apiUrl = process.env.REACT_APP_API_TESTING;
  imageUrl = process.env.REACT_APP_IMAGE_URL;
} else if (env === "staging") {
  apiUrl = process.env.REACT_APP_API_STAGING;
  imageUrl = process.env.REACT_APP_IMAGE_URL;
} else if (env == "production") {
  apiUrl = process.env.REACT_APP_API_STAGING;
  imageUrl = process.env.REACT_APP_IMAGE_URL;
}
export const api = apiUrl;
// import { useNavigate } from "react-router-dom";
export const saveRecords = async (url, payload) => {
  try {
    // Fetch token from localStorage
    const response = await fetch(apiUrl + url, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload), // Stringify the payload
    });
    console.log("payload===>", payload);
    
    return response.json();
  } catch (error) {
    console.error("fetahcData Error fetching data:", error);
    throw error; // Re-throw the error for further handling if needed
  }
};

export const getRecords = async (url, payload) => {
  try {
    console.log("url==>", url);
    
    const response = await fetch(apiUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  } catch (error) {
    console.error("fetchData Error fetching data:", error);
    throw error;
  }
};

export const dropdownData = async (responseData, fieldName, selectedFieldName, isOtherDependentOnThis) => {
  try {
    if (!responseData && responseData.length == 0) {
      return []; // Return empty array if no data is available
    }
    var options = [];
    responseData.forEach((element) => {
      options.push({ label: element[fieldName], value: element.id, fieldName: selectedFieldName, isOtherDependentOnThis });
    });
    const dropdownResponseData = [{ options: options, },];
    return dropdownResponseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately (throw error, display error message, etc.)
    throw error; // Re-throw the error for further handling if needed
  }
};
export const postData = async (apiName, data) => {
    var options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Ensure data is JSON stringified
    };

    try {
        const response = await fetch(apiUrl + apiName, options);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};