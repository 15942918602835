import React, { useState, useEffect } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { getRecords, saveRecords } from "../../services/api.service";
import { toast } from "react-toastify";

export default function UserProfile() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [usersDetails, setUsersDetails] = useState([]);

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "vendor_users",
        whereCondition: {
          id: id,
        },
      };
      const responseData = await getRecords("/common/getRecords", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        setUsersDetails(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }     
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="tabs-animation">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <h3>View</h3>
                <button
                  className="btn btn-primary"
                  onClick={(e) => navigate("/dashboard")}
                >
                  Back
                </button>
              </div>
              <div
                className="mt-3"
                style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '20px',
                // backgroundColor: '#f9f9f9',
                }}
            >
                <div className="row">
                  <div className="col">
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr>
                          <td style={styles.label}>Username</td>
                          <td style={styles.value}>{usersDetails.username_127 || "-"}</td>
                          </tr>
                          <tr>
                          <td style={styles.label}>Mobile No</td>
                          <td style={styles.value}>{usersDetails.mobile_no_67 || "-"}</td>
                          </tr>
                          <tr>
                          <td style={styles.label}>Email Id</td>
                          <td style={styles.value}>{usersDetails.email_id_68 || "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                  </div>   
                  <div className="col">
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr>
                          <td style={styles.label}>Name</td>
                              <td style={styles.value}>{usersDetails.name_66 || "-"}</td>
                          </tr>
                          <tr>
                          <td style={styles.label}>Is Active</td>
                              <td style={styles.value}>
                                  {usersDetails.is_active_157 === 1 ? "Active" : usersDetails.is_active_157 === 0 ? "Inactive" : "-"}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>           
                </div>

            </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
}
const styles = {
  label: {
    fontWeight: "bold",
    padding: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
  },
  value: {
    padding: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#f5f5f5",
  },
};
