import React from "react";
import "./CheckBoxInput.css"
import CustomError from "../Errors/CustomError";

const CheckboxInput = ({
  label,
  name,
  value = "",
  checked,
  handleTextChange,
  parentClassName = "",
  checkBoxClassName = "",
  labelClassName = "",
  parentStyle = {},
  checkboxStyle = {},
  labelStyle = {},
  disabled = false,
  readOnly = false,
  required = false,
  customError,
}) => {
  return (
    <>
      <div
        className={
          parentClassName && parentClassName.trim() !== ""
            ? parentClassName
            : "default-checkbox-parent-class"
        }
        style={parentStyle}>
        <input
          name={name}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={handleTextChange}
          className={
            checkBoxClassName && checkBoxClassName.trim() !== ""
              ? `${checkBoxClassName}`
              : "default-checkbox-input-class"
          }
          style={checkboxStyle}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
        />
        <label
          className={
            labelClassName && labelClassName.trim() !== ""
              ? labelClassName
              : "default-checkbox-label-class"
          }
          style={labelStyle}>
          {label}
        </label>
      </div>
      {customError && <CustomError error={customError} />}
    </>
  );
};

export default CheckboxInput;
