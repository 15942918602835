// Required field validation
export const required = (value, field) => {
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  if (Array.isArray(value)) {
    return value.length === 0 ? `${capitalizedField} Is Required.` : '';
  }
  return !value ||
      value == '00:00:00' ||
      (typeof value === 'string' && value?.trim() === '')
      ? `${capitalizedField} Is Required.`
      : '';
};

// Email validation
export const isEmail = (value, field) => {
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return `Invalid ${capitalizedField} address.`;
  }
  return "";
};

// Number validation
export const isNumber = (value, field) => {
  if (isNaN(value)) {
    return `${field} must be a valid number.`;
  }
  return "";
};

// Alphabet-only validation
export const isAlphabet = (value, field) => {
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  const alphabetRegex = /^[A-Za-z\s]*$/;
  if (!alphabetRegex.test(value)) {
    return `${capitalizedField} must contain only letters.`;
  }
  return "";
};

// Min length validation
export const minLength = (length) => {
  return (value, field) => {
    const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
    const stringValue = String(value)?.trim();
    if (stringValue.length < length) {
      return `${capitalizedField} must be at least ${length} characters long.`;
    }
    return "";
  };
};

// Max length validation
export const maxLength = (length) => {
  return (value, field) => {
    const stringValue = String(value)?.trim();
    const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
    if (stringValue.length > length) {
      return `${capitalizedField} must be no longer than ${length} characters.`;
    }
    return "";
  };
};

// Password validation
export const isPassword = (value, field) => {
  // Check for at least one special character
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  const specialCharRegex = /[!@#$%^&*()\-_=+\|[\]{};:\/?.]/;
  if (!specialCharRegex.test(value)) {
    return `${capitalizedField} must contain at least one special character.`;
  }

  // Check for at least one number
  const numberRegex = /\d/;
  if (!numberRegex.test(value)) {
    return `${field} must contain at least one number.`;
  }

  // Check for at least one alphabetic character (optional, depending on your requirement)
  const alphabetRegex = /[A-Za-z]/;
  if (!alphabetRegex.test(value)) {
    return `${field} must contain at least one letter.`;
  }

  // Check for a minimum length of 8 characters
  if (value.length < 8) {
    return `${field} must be at least 8 characters long.`;
  }
  return "";
};

// Phone number validation
export const isPhoneNumber = (value, field) => {
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  const phoneRegex = /^\d{10,12}$/;
  if (!value?.match(/^\d+$/)) {
    return `${capitalizedField} must contain only digits.`;
  }
  if (!phoneRegex?.test(value)) {
    return `${capitalizedField} must be a valid between 10 to 12 digits.`;
  }
  return "";
};

// Date validation
export const isValidDate = (value, field) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(value)) {
    return `${field} must be in the proper dd-mm-yyyy format.`;
  }
  return "";
};

// Date validation
export const isValidTime = (value, field) => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
  if (!timeRegex.test(value?.trim())) {
    return `${field} must be in the proper hh:mm:ss format.`;
  }
  return "";
};

// Checkbox validation
export const isChecked = (value, field) => {
  const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
  if (!value) {
    return `${capitalizedField} must be checked.`;
  }
  return "";
};

// URL validation
export const isURL = (value, field) => {
  const urlRegex = /^(https?:\/\/)?([^\s.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  if (!urlRegex.test(value)) {
    return `${field} must be a valid URL.`;
  }
  return "";
};
