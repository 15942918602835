import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Button,
} from 'reactstrap';
import { createPageData } from './VendorUserFile';
import { getRecords } from '../../services/api.service';

const VendorUserView = ({ }) => {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams(); 
    const { payload, navigatePage, headerName, validationType } =
        createPageData();
        useEffect(() => {
            if (id) {
                getData(id);
            }
        }, [id]);
   const getData = async (id) => {
       try {
           let payload = {
               modelName: 'vendor_users',
               whereCondition: {
                   id: id,
               },
           };
           const responseData = await getRecords('/common/getRecords', payload);
           if (responseData.data && responseData.data.length > 0) {
               const data = responseData.data[0];
               setData(data);
           }
       } catch (error) {
           console.error('Error fetching data:', error);
       } finally {
           //setLoadingData(false);
       }
   };
    const handleBackClick = () => {
        navigate(navigatePage?.back);
    };
    console.log("data...view",data);
    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <Card className='shadow-lg rounded'>
                        <CardHeader
                            className='d-flex justify-content-between align-items-center'
                            style={{
                                backgroundColor: '#f8f9fa',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            <h4 className='mb-0'>{headerName} View</h4>
                            <Button
                                color='primary'
                                onClick={handleBackClick}
                                className='px-3 py-1'
                            >
                                Back
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <fieldset
                                style={{
                                    border: '1px solid #ddd',
                                    padding: '15px',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <div className='row'>
                                    <div className='col'>
                                        <table className='table table-striped table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Username
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {data?.username_127}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Mobile No
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {data?.mobile_no_67}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Email Id
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {data?.email_id_68}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='col'>
                                        <table className='table table-striped table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Name</strong>
                                                    </td>
                                                    <td>
                                                        {data?.name_66}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Is Active
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {data?.is_active_157 ===
                                                        1
                                                            ? 'Active'
                                                            : 'Inactive'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </fieldset>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default VendorUserView;
