import React, { useState, useEffect } from "react";


const useFileUpload = (id, formData,setFormData) => {
  const [uploadImage, setUploadImage] = useState("");
  const [uploadMedia, setUploadMedia] = useState("");
  const [isDownloadingMedia, setIsDownloadingMedia] = useState(false);
  const [isDownloadingImage, setIsDownloadingImage] = useState(false);
  const [removeDownloadIcons, setRemoveDownloadIcons] = useState({});

  useEffect(() => {
    if (id) {
      setUploadImage({
        type: "image",
        data: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
      });
      setUploadMedia({
        type: "video",
        data: "https://videos.pexels.com/video-files/7657449/7657449-sd_640_360_25fps.mp4",
      });
    }
  }, [id]);

  const handleFileUpload = async (files, name) => {
    if (!files || files.length === 0) return;
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      let base64Data = reader.result;
      try {
        const updatedData = { type: file.type, data: base64Data };
        if (name == "image") {
          setUploadImage(updatedData);
        } else {
          setUploadMedia(updatedData);
        }
        // Dynamically update the state for the removal icon
        setRemoveDownloadIcons((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        setFormData((prevState) => ({
          ...prevState,
          uploadMedia: {
            ...prevState.uploadMedia,
            imagePath: base64Data,
          },
        }));
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleDownloadMediaClick = async (mediaFile) => {
    console.log("mediaFile==>", mediaFile);

    if (mediaFile?.type == "image") {
      try {
        setIsDownloadingImage(true);
        const urlSegments = mediaFile.data.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        // await downloadBlobData(academics, lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIsDownloadingImage(false);
      }
    } else {
      try {
        setIsDownloadingMedia(true);
        const urlSegments = mediaFile.data.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        // await downloadBlobData(academics, lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIsDownloadingMedia(false);
      }
    }
  };

  const handleRemoveMediaClick = (name) => {
    if (name == "image") {
      setUploadImage("");
      setFormData((prevState) => ({
        ...prevState,
        uploadMedia: {
          ...prevState.uploadMedia,
          imagePath: "",
        },
      }));
    } else {
      setUploadMedia("");
      setFormData((prevState) => ({
        ...prevState,
        uploadMedia: {
          ...prevState.uploadMedia,
          imagePath: "",
        },
      }));
    }
    setRemoveDownloadIcons((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  return {
    handleFileUpload,
    handleDownloadMediaClick,
    handleRemoveMediaClick,
    uploadImage,
    uploadMedia,
    isDownloadingImage,
    isDownloadingMedia,
    removeDownloadIcons,
  };
};
export default useFileUpload;
