import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { apiError, loginUserSuccessful, checkLogin } from '../../store/actions';
import logolight from '../../assets/images/logo-light.png';
import loginLogo from '../../assets/images/logo.jpg';
import { postData } from '../../services/api.service';
import Swal from 'sweetalert2';

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const loginError = useSelector((state) => state.Login.loginError);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(apiError(''));
        document.body.classList.add('auth-body-bg');

        return () => {
            document.body.classList.remove('auth-body-bg');
        };
    }, [dispatch]);

    const handleSubmit = async (event, values) => {
         event.preventDefault();
        const { username, password } = values;

        if (!username || !password) {
            dispatch(apiError('Both username and password are required'));
        } else {
            const payload = {
                username,
                password,
                isactive: 1,
                whereCondition: {
                    is_authentication_form: 1,
                },
            };

            try {
                const response = await postData(
                    '/vendors/auth/login/user-login',
                    payload
                );
                if (response.code === 1) {
                    localStorage.setItem(
                        'authUser',
                        JSON.stringify(response.data)
                    );
                    localStorage.setItem(
                        'vendorId',
                        response.data.vendor_id_65
                    );

                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.message,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/dashboard');
                        }
                    });
                } else {
                    dispatch(
                        apiError(response.message)
                    );
                }
            } catch (error) {
                dispatch(apiError('An error occurred during login.'));
                console.error(error);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <React.Fragment>
            <div>
                <Container fluid className='p-0'>
                    <Row className='g-0'>
                        <Col lg={4}>
                            <div className='custom-col-wrapper'>
                                <div className='custom-authentication-bg'></div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='custom-authentication-page-content authentication-page-content p-4 min-vh-100 xs-min-vh-50'>
                                <div className='w-100 xs-w-50'>
                                    <Row className='justify-content-start sm-justify-content-center xs-justify-content-center'>
                                        <Col lg={8}>
                                            <div className='text-start'>
                                                <Link
                                                    to='/'
                                                    className='mb-8 d-block'
                                                >
                                                    <img
                                                        src={loginLogo}
                                                        alt='Grind Master Logo'
                                                        height='60'
                                                    />
                                                </Link>
                                                <h3 className='font-size-24 mt-4 text-muted'>
                                                    Welcome Vendor,
                                                </h3>
                                                <h4 className='text-muted'>
                                                    Please sign in to your
                                                    account
                                                </h4>
                                            </div>

                                            {loginError && (
                                                <Alert color='danger'>
                                                    {loginError}
                                                </Alert>
                                            )}

                                            <div className='custom-login-form-container p-2 mt-2'>
                                                <AvForm
                                                    className='form-horizontal'
                                                    onValidSubmit={handleSubmit}
                                                >
                                                    <div className='row border rounded p-4 mx-auto'>
                                                        <div className='col-md-6'>
                                                            <Label htmlFor='username'>
                                                                Email
                                                            </Label>
                                                            <AvField
                                                                name='username'
                                                                value={
                                                                    formData.username
                                                                }
                                                                type='text'
                                                                className='form-control'
                                                                id='username'
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            'Email is required',
                                                                    },
                                                                    pattern: {
                                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                                        errorMessage:
                                                                            'Invalid email format',
                                                                    },
                                                                }}
                                                                placeholder='Enter Email here...'
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Label htmlFor='userpassword'>
                                                                Password
                                                            </Label>
                                                            <AvField
                                                                name='password'
                                                                value={
                                                                    formData.password
                                                                }
                                                                type='password'
                                                                className='form-control'
                                                                id='userpassword'
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage:
                                                                            'Password is required',
                                                                    },
                                                                }}
                                                                placeholder='Enter Password here...'
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end align-items-center mt-4'>
                                                        <Link
                                                            to='/forgot-password'
                                                            className='me-3'
                                                        >
                                                            Recover Password
                                                        </Link>
                                                        <Button
                                                            color='primary'
                                                            className='waves-effect waves-light'
                                                            type='submit'
                                                        >
                                                            Login to Dashboard
                                                        </Button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    loginError: state.Login.loginError,
});

export default connect(mapStateToProps, {
    apiError,
    loginUserSuccessful,
    checkLogin,
})(Login);
