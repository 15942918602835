import React, { useMemo, useCallback } from 'react';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Card, CardBody, Button, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { generateColumns } from '../../custom/Utils/ColumnUtils';
import useFetchData from '../../custom/Utils/UseFetchDataUtils';
import usePagination from '../../custom/Utils/UsePaginationUtils';
import { listPageData } from './VendorUserFile';

const VendorUserList = () => {
    const navigate = useNavigate();
    const { payload, navigatePage, headerName, columnData,searchFields } = listPageData();
    const initialPayload = useMemo(() => payload, []);

    // pagination
    const {
        page,
        pageSize,
        nextPage,
        previousPage,
        lastPage,
        firstPage,
        handlePageSizeChange,
        setPage,
    } = usePagination();


    // fetch data using the provided payload, page, and page size
    const url = '/common/getRecords';
    const { data, isLoading, setSearchKeyword, totalCount } = useFetchData(
        initialPayload,
        page,
        pageSize,
        url,
        searchFields
    );
    // handle create action
    const handleCreate = () => navigate(navigatePage.create);

    // handle edit action
    const handleEdit = useCallback(
        (id) => {
            navigate(navigatePage.edit + '/' + id);
        },
        [navigate, navigatePage.edit]
    );
    const handleView = useCallback(
        (id) => {
            navigate(navigatePage.view + '/' + id);
        },
        [navigate, navigatePage.view]
    );

    // columns data
    const columns = useMemo(
        () => generateColumns(data, columnData, handleEdit, handleView),
        [data, columnData, handleEdit, handleView]
    );

    // handle page size change callback
    const handlePageSizeChangeWrapper = useCallback(
        (newSize) => {
            if (newSize !== pageSize) {
                handlePageSizeChange(newSize, totalCount);
                setPage(0); // Reset to first page when page size changes
            }
        },
        [handlePageSizeChange, pageSize, totalCount, setPage]
    );

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <Breadcrumbs title={headerName} breadcrumbItems={[]} />
                        <Button
                            color='primary'
                            className='waves-effect waves-light me-1'
                            onClick={handleCreate}
                            style={{ marginLeft: 'auto' }}
                        >
                            Create {headerName}
                        </Button>
                    </div>

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={data || []}
                                isLoading={isLoading}
                                onSearchInputData={setSearchKeyword}
                                pageIndexProp={page}
                                onPageSizeChange={handlePageSizeChangeWrapper}
                                nextToPage={nextPage}
                                lastToPage={() => lastPage(totalCount)}
                                previousToPage={previousPage}
                                previousFirstToPage={firstPage}
                                totalCount={totalCount}
                                customPageSize={pageSize}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default VendorUserList;
