const vendorId = localStorage.getItem('vendorId');
export const intialformData = () => ({
    inputData: {
        project_id: 1, //set static data
        vendor_id_65: vendorId,
        name_66: '',
        mobile_no_67: '',
        email_id_68: '',
        password_69: '',
        is_main_user_111: 0, //set static data
        username_127: '',
        is_active_157: 1,
    },
});
 
export const createPageData = () => {
    const payload = {
        modelName: 'vendor_users',
        whereCondition: {
            is_active: true,
        },
    };
 
    const navigatePage = { back: '/vendor-user-list' };

    const headerName = 'Vendor User';

    const validationType = 'VendorCreate';
    return {
        payload,
        navigatePage,
        headerName,
        validationType,
    };
};

export const listPageData = () => {
     const vendorId = localStorage.getItem('vendorId');
    const payload = {
        modelName: 'vendor_users',
        whereCondition: {
            vendor_id_65: vendorId,
        },
    };
    const columnData = [
        { label: 'sr.no', value: 'index' },
        { label: 'Username', value: 'username_127' },
        { label: 'Mobile No', value: 'mobile_no_67' },
        { label: 'Email Id', value: 'email_id_68' },
        { label: 'Name', value: 'name_66' },
        { label: 'Is Active', value: 'is_active_157'},
        { label: 'Action', value: 'actions' },
    ];

    const navigatePage = { create: '/vendor-user-create', edit: '/vendor-user-create', view: '/vendor-user-view' };

    const headerName = 'Vendor User';
     const searchFields = [
         'username_127',
         'mobile_no_67',
         'email_id_68',
         'name_66',
         'is_active_157',
     ];

    return { payload, navigatePage, headerName, columnData,searchFields };
};
