import React from "react";

function CustomError({ error }) {
  return (
    <span>
      <p
        style={{
          fontSize: "11.5px",
          margin: "0 0 0 0",
          padding: "0px",
          marginLeft: "2px",
          marginTop: "2px",
        }}
        className="text-danger">
        {error}
      </p>
    </span>
  );
}

export default CustomError;
