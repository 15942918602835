import React, { useState, useEffect } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { getRecords, saveRecords } from "../../services/api.service";
import { toast } from "react-toastify";
import moment from "moment";

export default function ApproveOrRejectView() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [poData, setPoData] = useState([]);
  const [poLogData, setPoLogData] = useState([]);
  const [poLinesData, setPoLinesData] = useState([]);
  const [buyerGroup, setBuyerGroup] = useState([]);
  const [loadingData, setLoadingData] = useState(!!id);
  const [validationErrors, setValidationErrors] = useState({});

  const [formData, setFormData] = useState({
    committedDate1: "",
    committedDate2: "",
    remark: "",
  });

  const breadcrumbItems = [
    { title: "Grind Master Vendor Portal", link: "/" },
    { title: "Dashboard", link: "#" },
  ];

  useEffect(() => {
    if (id) {
      getData(id);
      getPoLogData(id);
      getPoLinesData(id);
    }
  }, [id]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "po_managements",
        whereCondition: {
          id: id,
        },
        relations: [
          {
            module: "warehouse_masters",
            moduleAs: "warehouse_masters",
          },
          {
            module: "vendor_masters",
            moduleAs: "vendor_masters",
          },
          {
              "module": "po_status",
              "moduleAs": "po_status"
          }
        ],
      };
      const responseData = await getRecords("/common/getRecords", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        setPoData(data);
        getBuyerGroupDetails(data.buyer_group_id_162);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getPoLogData = async (id) => {
    try {
      let payload = {
        modelName: "po_logs",
        whereCondition: {
          po_id_149: id,
        },
        relations: [
          {
            module: "po_status",
            moduleAs: "po_status",
          },
        ],
      };
      const responseData = await getRecords("/common/getRecords", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data;
        setPoLogData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getPoLinesData = async (id) => {
    try {
      let payload = {
        modelName: "po_details",
        whereCondition: {
          po_id_126: id,
        },
        relations: [
          {
            module: "vendor_item_masters",
            moduleAs: "vendor_item_masters",
          },
        ],
      };
      const responseData = await getRecords("/common/getRecords", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data;
        setPoLinesData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = (context) => {
    const errors = {};
    
    // Always validate remark
    if (!formData.remark) {
      errors.remark = "Remark is required.";
    }
  
    
    // Validate committedDate1 and remark for 'Accept' context
    if (context === "Accept") {
      if (!formData.committedDate1 && !poData.delivery_date_1_95) {
        errors.committedDate1 = "Committed Date 1 is required.";
      }
    }

    // Validate committed dates only for specific contexts (not 'Revised' or 'Rejected')
    if (context !== "Revised" && context !== "Reject" && context !== "Accept" && context !== "Dispatch" && context !== "WIP") {
      if (!formData.committedDate1 && !poData.delivery_date_1_95) {
        errors.committedDate1 = "Committed Date 1 is required.";
      }
      // if (!formData.committedDate2 && poData.delivery_date_1_95) {
      //   errors.committedDate2 = "Committed Date 2 is required.";
      // }
    }

    // Validate dispatch and remark for Dispatch context
    if (context === "Dispatch") {
      if (!formData.remark) {
        errors.remark = "Remark is required.";
      }
    }
  
    return errors;
  };
  
  const handleErrors = (errors) => {
    const errorFields = Object.keys(errors)
      .map((field) => {
        switch (field) {
          case "remark":
            return "Remark";
          case "committedDate1":
            return "Committed Date 1";
          case "committedDate2":
            return "Committed Date 2";
          default:
            return field;
        }
      })
      .join(", ");
    
    toast.error(`${errorFields} is Mandatory`);
  };

  // Custom function to send logs payload
  const sendLogsPayload = async (id, remark, status) => {
    const storedUserData = localStorage.getItem("authUser");
    const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
    const payloadForLogs = {
      modelName: "po_logs",
      inputData: {
        po_id_149: id,
        remark_150: remark,
        type_151: 2,
        status_152: status,
        updated_by_153: parsedUserData.id, // User ID from local storage
        date_155: moment().format("YYYY-MM-DD HH:mm:ss"), // Current date formatted
      },
    };

    try {
      const responseLogs = await saveRecords(
        "/common/saveRecords",
        payloadForLogs
      );
      return responseLogs;
    } catch (error) {
      console.error("Error sending logs payload:", error);
      toast.error(error);
    }
  };

  // code for the PO Accept
  const handleAccept = async (e) => {
    e.preventDefault();

    // Pass "Accept" as context
    const errors = validateForm("Accept");
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      handleErrors(errors); // Display field-specific error messages
      return;
    }

    try {
      setLoadingData(true);

      const inputData = {
        po_status_125: 1,
        remark_132: formData.remark,
      };

      // Check if poData.delivery_date_1_95 exists
      if (poData.delivery_date_1_95) {
        // Use committedDate2 as delivery_date_2_96
        inputData.delivery_date_2_96 = formData.committedDate2;
      } else {
        // Use committedDate1 as delivery_date_1_95
        inputData.delivery_date_1_95 = formData.committedDate1;
      }

      const payload = {
        id: id,
        modelName: "po_managements",
        inputData,
      };

      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(id, formData.remark, inputData.po_status_125);
        toast.success("PO Accepted Successfully");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // code for the PO Requesting For QA
  const handleRequestForQA = async (e) => {
    e.preventDefault();

    const errors = validateForm("RequestForQA");
    setValidationErrors(errors);
  
    if (Object.keys(errors).length > 0) {
      handleErrors(errors);
      return;
    }

    try {
      setLoadingData(true);
      const payload = {
        id: id,
        modelName: "po_managements",
        inputData: {
          po_status_125: 9,
          remark_132: formData.remark,
        },
      };
      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(
          id,
          formData.remark,
          payload.inputData.po_status_125
        );
        toast.success("PO Added to Request for QA Successfully");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // code for the Po Revised
  const handleRevised = async (e) => {
    e.preventDefault();

    const errors = validateForm("Revised");
    setValidationErrors(errors);
  
    if (Object.keys(errors).length > 0) {
      handleErrors(errors);
      return;
    }

    try {
      setLoadingData(true);
      const payload = {
        id: id,
        modelName: "po_managements",
        inputData: {
          po_status_125: 2,
          remark_132: formData.remark,
        },
      };
      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(
          id,
          formData.remark,
          payload.inputData.po_status_125
        );
        toast.success("PO Revised Successfully.");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // code for the PO Rejection
  const handleRejected = async (e) => {
    e.preventDefault();

    const errors = validateForm("Reject");
    setValidationErrors(errors);
  
    if (Object.keys(errors).length > 0) {
      handleErrors(errors);
      return;
    }

    try {
      setLoadingData(true);
      const payload = {
        id: id,
        modelName: "po_managements",
        inputData: {
          po_status_125: 7,
          remark_132: formData.remark,
        },
      };
      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(
          id,
          formData.remark,
          payload.inputData.po_status_125
        );
        toast.success("PO Rejected Successfully.");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // code for the PO Dispatch
  const handleDispatch = async (e) => {
    e.preventDefault();

    // Pass "Dispatch" as context
    const errors = validateForm("Dispatch");
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      handleErrors(errors); // Display field-specific error messages
      return;
    }

    try {
      setLoadingData(true);
      const payload = {
        id: id,
        modelName: "po_managements",
        inputData: {
          po_status_125: 5,
          remark_132: formData.remark,
        },
      };
      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(
          id,
          formData.remark,
          payload.inputData.po_status_125
        );
        toast.success("PO Dispatched Successfully.");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackToWIP = async (e) => {
    e.preventDefault();

    // Pass "Dispatch" as context
    const errors = validateForm("WIP");
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      handleErrors(errors); // Display field-specific error messages
      return;
    }

    try {
      setLoadingData(true);
      const payload = {
        id: id,
        modelName: "po_managements",
        inputData: {
          po_status_125: 1,
          remark_132: "test",
        },
      };
      const responseData = await saveRecords("/common/saveRecords", payload);
      if (responseData.code === 1) {
        await sendLogsPayload(
          id,
          formData.remark,
          payload.inputData.po_status_125
        );
        toast.success("PO added to WIP Successfully.");
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getBuyerGroupDetails = async (id) => {
    console.log("buyer group Id===>", id);

    try {
      let payload = {
        modelName: "buyer_group_masters",
        whereCondition: {
          id: id,
        },
      };
      const responseData = await getRecords("/common/getRecords", payload);
      if (responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        setBuyerGroup(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

    const formatDate = (dateString) => {
      if (!dateString) return "-";

      // Check for ISO 8601 format (contains "T")
      if (dateString.includes("T")) {
          const date = new Date(dateString);
          if (isNaN(date)) return "-"; // Invalid ISO date
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
      }

      // Check for YYYY-MM-DD format
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
          const [year, month, day] = dateString.split("-").map(Number);
          const date = new Date(year, month - 1, day); // Month is 0-indexed
          if (isNaN(date)) return "-";
          return `${String(day).padStart(2, "0")}-${String(month).padStart(2, "0")}-${year}`;
      }

      // Handle DD-MM-YYYY format
      const parts = dateString.split("-");
      if (parts.length === 3) {
          const [day, month, year] = parts.map(Number);
          const date = new Date(year, month - 1, day); // Month is 0-indexed
          if (isNaN(date)) return "-"; // Invalid date
          return dateString; // Already in DD-MM-YYYY format
      }

      return "-"; // If format is not recognized
  };



  const formatLogDate = (dateString) => {
    const date = new Date(dateString);

    // Format date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    // Format time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year} ${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="tabs-animation">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <h3>View</h3>
                <button
                  className="btn btn-primary"
                  onClick={(e) => navigate("/dashboard")}
                >
                  Back
                </button>
              </div>

              {/* PO Headers Table with Legend */}
              <fieldset
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <legend
                  style={{
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  PO Headers
                </legend>
                <div className="row">
                  <div className="col">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td>
                          <b>PO Number</b>
                          </td>
                          <td>{poData.po_number_72 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>PO Date</strong>
                          </td>
                          <td>
                          <td>{formatDate(poData.po_date_73)}</td>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Vendor Code</strong>
                          </td>
                          <td>
                            {poData.vendor_masters?.vendor_code_44 || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Vendor Name</strong>
                          </td>
                          <td>{poData.vendor_name_76 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Site</strong>
                          </td>
                          <td>{poData.site_78 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Warehouse</strong>
                          </td>
                          <td>
                            {poData.warehouse_masters?.warehouse_name_21 || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Location</strong>
                          </td>
                          <td>{poData.location_80 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Buyer Group</strong>
                          </td>
                          <td>{poData.buyer_group_81 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>PO Owner</strong>
                          </td>
                          <td>{poData.po_owner_82 || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <strong>PO Indentor</strong>
                          </td>
                          <td>{poData.po_indentor_83 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Indentor Mobile No</strong>
                          </td>
                          <td>{poData.indentor_mobile_no_84 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>TDS Applicable</strong>
                          </td>
                          <td>{poData.tds_applicable_85 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>TDS Group</strong>
                          </td>
                          <td>{poData.tds_group_86 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mode of Delivery</strong>
                          </td>
                          <td>{poData.mode_of_delivery_88 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Payment Terms</strong>
                          </td>
                          <td>{poData.payment_terms_89 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Method of Payment</strong>
                          </td>
                          <td>{poData.method_of_payment_90 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Cost Center</strong>
                          </td>
                          <td>{poData.cost_center_91 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Department</strong>
                          </td>
                          <td>{poData.department_92 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Project</strong>
                          </td>
                          <td>{poData.project_93 || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Delivery Date</strong>
                          </td>
                          <td>
                            {poData.delivery_date_87
                              ? formatDate(poData.delivery_date_87)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Min. GRN Date</strong>
                          </td>
                          <td>
                            {poData.minimum_grn_date_94
                              ? formatDate(poData.minimum_grn_date_94)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Committed Date 1</strong>
                          </td>
                          <td>
                            {formatDate(poData.delivery_date_1_95)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Committed Date 2</strong>
                          </td>
                          <td>
                            {poData.delivery_date_2_96
                              ? formatDate(poData.delivery_date_2_96)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>PO Approval</strong>
                          </td>
                          <td>{poData.po_status?.name || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>PO Status</strong>
                          </td>
                          <td>{poData.po_status_microsoft_130 || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Remark</strong>
                          </td>
                          <td>{poData.remark_132 || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>

              <fieldset
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <legend
                  style={{
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  PO Lines
                </legend>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Other Charges</th>
                        <th>Assessable Value</th>
                        <th>Net Amount</th>
                        <th>HSN</th>
                        <th>SAC</th>
                        <th>PO Line Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {poLinesData && poLinesData.length > 0 ? (
                        poLinesData.map((log, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{log.item_code_113 || "-"}</td>
                            <td>
                              {log.vendor_item_masters?.item_name_39 || "-"}
                            </td>
                            <td>{log.vendor_item_masters?.unit_41 || "-"}</td>
                            <td>{log.quantity_115 || "-"}</td>
                            <td>{log.price_116 || "-"}</td>
                            <td>{log.other_charges_117 || "-"}</td>
                            <td>{log.assessable_value_119 || "-"}</td>
                            <td>{log.net_amount_118 || "-"}</td>
                            <td>{log.hsn_120 || "-"}</td>
                            <td>{log.sac_121 || "-"}</td>
                            <td>{log.po_line_status_156 || "-"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </fieldset>

              {/* PO Log Section with Legend */}
              <fieldset
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
                className="mt-4"
              >
                <legend
                  style={{
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  PO Log
                </legend>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Updated By</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Remark</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poLogData && poLogData.length > 0 ? (
                      poLogData.map((log, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {log.type_151 === 1
                              ? log.user_master?.employee_master
                                ? `${
                                    log.user_master.employee_master.first_name
                                  } ${
                                    log.user_master.employee_master
                                      .middle_name || ""
                                  } ${
                                    log.user_master.employee_master.last_name
                                  }`
                                : "-"
                              : log.type_151 === 2
                              ? log.vendor_user?.name_66 || "-"
                              : "-"}
                          </td>
                          <td>{log.type_151 === 1 ? "GMMPL" : log.type_151 === 2 ? "Vendor" : "-"}</td>
                          <td>{log.po_status?.name || "-"}</td>
                          <td>{log.remark_150 || "-"}</td>
                          <td>
                            {log.date_155 ? formatLogDate(log.date_155) : "-"}
                          </td>{" "}
                          {/* Format date if needed */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </fieldset>

              <div className="mt-4">
              {poData.po_status_125 === 11 ? (
                // Render only the "Back To WIP" button and Remark field
                <div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label htmlFor="remark" className="form-label">
                        Remark
                      </label>
                      <textarea
                        id="remark"
                        name="remark"
                        rows={4}
                        className={`form-control ${
                          validationErrors?.remark ? "is-invalid" : ""
                        }`}
                        placeholder="Please Enter Remark"
                        value={formData.remark}
                        onChange={(e) => handleTextChange(e)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={(e) => handleBackToWIP(e)}
                    >
                      Back To WIP
                    </button>
                  </div>
                </div>
                ) : poData.po_status_125 === 7 ||
                  poData.po_status_125 === 2 ? null : (
                  // Render the form and other buttons when po_status_125 is not 11, 7, or 2
                  <form>
                    <div className="row mb-3">
                      {poData.delivery_date_1_95 ? (
                        <div className="col-md-4">
                          <label htmlFor="committedDate2" className="form-label">
                            Committed Date 2
                          </label>
                          <input
                            type="date"
                            id="committedDate2"
                            name="committedDate2"
                            // className={`form-control ${
                            //   validationErrors?.committedDate2 ? "is-invalid" : ""
                            // }`}
                            className={`form-control`}
                            style={{ padding: "10px" }}
                            min={new Date().toISOString().split("T")[0]} // Disable past dates
                            value={formData.committedDate2}
                            onChange={(e) => handleTextChange(e)}
                          />
                        </div>
                      ) : (
                        <div className="col-md-4">
                          <label htmlFor="committedDate1" className="form-label">
                            Committed Date 1
                          </label>
                          <input
                            type="date"
                            id="committedDate1"
                            name="committedDate1"
                            className={`form-control ${
                              validationErrors?.committedDate1 ? "is-invalid" : ""
                            }`}
                            min={new Date().toISOString().split("T")[0]} // Disable past dates
                            style={{ padding: "10px" }}
                            value={formData.committedDate1}
                            onChange={(e) => handleTextChange(e)}
                          />
                        </div>
                      )}
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label htmlFor="remark" className="form-label">
                          Remark
                        </label>
                        <textarea
                          id="remark"
                          name="remark"
                          rows={4}
                          className={`form-control ${
                            validationErrors?.remark ? "is-invalid" : ""
                          }`}
                          placeholder="Please Enter Remark"
                          value={formData.remark}
                          onChange={(e) => handleTextChange(e)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center gap-2">
                      {/* Render only the Dispatch button if po_status_125 is 10 */}
                      {poData.po_status_125 === 10 ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => handleDispatch(e)}
                        >
                          Dispatch
                        </button>
                      ) : (
                        // Render other buttons if po_status_125 is not 10
                        <>
                          {poData.po_status_125 !== 2 && (
                            <>
                              {poData.po_status_125 !== 1 && (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={(e) => handleAccept(e)}
                                >
                                  Accept
                                </button>
                              )}
                              {poData.po_status_125 === 1 &&
                                buyerGroup.qa_is_required_168 === "1" && (
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={(e) => handleRequestForQA(e)}
                                  >
                                    Request For QA
                                  </button>
                                )}
                              {poData.po_status_125 === 1 &&
                                buyerGroup.qa_is_required_168 === "0" && (
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={(e) => handleDispatch(e)}
                                  >
                                    Dispatch
                                  </button>
                                )}
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => handleRevised(e)}
                              >
                                Revise
                              </button>
                              {poData.po_status_125 !== 1 && (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={(e) => handleRejected(e)}
                                >
                                  Reject
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
}
