import React, { useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import TextInput from '../../custom/components/Text-Input/TextInput';
import CheckboxInput from '../../custom/components/CheckBox-Input/CheckboxInput.js';
import CustomForm from '../../custom/Forms/CustomForm';
import { getRecords, saveRecords } from '../../services/api.service.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCreateForm from '../../custom/Utils/UseCreateFormUtils.js';
import { createPageData, intialformData } from './VendorUserFile.js';
import { validate } from '../../custom/validations/Validate.js';
import Swal from 'sweetalert2';

const VendorUserCreate = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const {
        payload,
        navigatePage,
        headerName,
        validationType,
    } = createPageData();
    const initialPayload = useMemo(() => payload, []);

    const {
        formData,
        onInputChange,
        formErrors,
        setLoadingData,
        loadingData,
        setFormErrors,
    } = useCreateForm(
        id,
        initialPayload,
        intialformData(),
        validationType,
    );
const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(validationType, formData?.inputData);
    if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
    }
    setLoadingData(true);
    const payload = {
        id: id,
        modelName: initialPayload?.modelName,
        inputData: formData.inputData,
    };

    const responseData = await saveRecords("/common/saveRecords",payload);
    if (responseData && responseData.code == 1) {
        Swal.fire({
            title: 'Success!',
            text: responseData.message,
            icon: 'success',
            confirmButtonText: 'OK',
        }).then(() => {
            navigate(navigatePage?.back);
        });
    } else {
        Swal.fire({
            title: 'Error',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'OK',
        }).then(() => {
            navigate(navigatePage?.back);
        });
    }
    setLoadingData(false);
};
    return (
        <CustomForm
            title={'CREATE ' + headerName}
            loadingData={loadingData}
            onFormSubmit={handleSubmit}
            handleBackClick={() => navigate(navigatePage?.back)}
            id={id}
        >
            <Row className='mt-2'>
                <Col md='4' className='mt-3'>
                    <TextInput
                        label='Username'
                        name='username_127'
                        value={formData?.inputData?.username_127}
                        onChange={onInputChange}
                        placeholder='Please Enter Username'
                        parentClassName=''
                        inputClassName='form-control'
                        labelClassName='form-label'
                        parentStyle={{}}
                        inputStyle={{}}
                        labelStyle={{}}
                        maxLength
                        minLength
                        readOnly={false}
                        disabled={false}
                        required={true}
                        spellcheck={false}
                        size
                        pattern='[A-Za-z0-9]+'
                        autocomplete={'off'}
                        customError={formErrors?.username_127}
                    />
                </Col>

                <Col md='4' className='mt-3'>
                    <TextInput
                        label='Mobile No'
                        name='mobile_no_67'
                        value={formData?.inputData?.mobile_no_67}
                        onChange={onInputChange}
                        placeholder='Please Enter Mobile No'
                        parentClassName=''
                        inputClassName='form-control'
                        labelClassName='form-label'
                        parentStyle={{}}
                        inputStyle={{}}
                        labelStyle={{}}
                        maxLength
                        minLength
                        readOnly={false}
                        disabled={false}
                        required={true}
                        spellcheck={false}
                        size
                        pattern='[A-Za-z0-9]+'
                        autocomplete={'off'}
                        customError={formErrors?.mobile_no_67}
                    />
                </Col>

                <Col md='4' className='mt-3'>
                    <TextInput
                        label='Email'
                        name='email_id_68'
                        value={formData?.inputData?.email_id_68}
                        onChange={onInputChange}
                        placeholder='Please Enter Email'
                        parentClassName=''
                        inputClassName='form-control'
                        labelClassName='form-label'
                        parentStyle={{}}
                        inputStyle={{}}
                        labelStyle={{}}
                        maxLength
                        minLength
                        readOnly={false}
                        disabled={false}
                        required={true}
                        spellcheck={false}
                        size
                        pattern='[A-Za-z0-9]+'
                        autocomplete={'off'}
                        customError={formErrors?.email_id_68}
                    />
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col md='4' className='mt-3'>
                    <TextInput
                        label='Password'
                        name='password_69'
                        value={formData?.inputData?.password_69}
                        onChange={onInputChange}
                        placeholder='Please Enter Password'
                        parentClassName=''
                        inputClassName='form-control'
                        labelClassName='form-label'
                        parentStyle={{}}
                        inputStyle={{}}
                        labelStyle={{}}
                        maxLength
                        minLength
                        readOnly={false}
                        disabled={false}
                        required={true}
                        spellcheck={false}
                        size
                        pattern='[A-Za-z0-9]+'
                        autocomplete={'off'}
                        customError={formErrors?.password_69}
                    />
                </Col>

                <Col md='4' className='mt-3'>
                    <TextInput
                        label='Name'
                        name='name_66'
                        value={formData?.inputData?.name_66}
                        onChange={onInputChange}
                        placeholder='Please Enter Name'
                        parentClassName=''
                        inputClassName='form-control'
                        labelClassName='form-label'
                        parentStyle={{}}
                        inputStyle={{}}
                        labelStyle={{}}
                        maxLength
                        minLength
                        readOnly={false}
                        disabled={false}
                        required={true}
                        spellcheck={false}
                        size
                        pattern='[A-Za-z0-9]+'
                        autocomplete={'off'}
                        customError={formErrors?.name_66}
                    />
                </Col>
                <Col lg='4' className='mt-5'>
                    <CheckboxInput
                        label='Is Active'
                        name='is_active_157'
                        value={formData?.inputData?.is_active_157}
                        checked={formData?.inputData?.is_active_157}
                        handleTextChange={onInputChange}
                        parentClassName='form-check'
                        checkBoxClassName='form-check-input'
                        labelClassName='form-check-label'
                        parentStyle={{}}
                        checkboxStyle={{}}
                        labelStyle={{}}
                        disabled={false}
                        required={false}
                        customError={formErrors.is_active_157}
                    />
                </Col>
            </Row>
        </CustomForm>
    );
};
export default VendorUserCreate;
