import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import user from "../../../assets/images/users/user.png";
import { getRecords } from "../../../services/api.service";
import { useNavigate } from "react-router-dom";

const ProfileMenu = (props) => {
    const [menu, setMenu] = useState(false);
    const [userData, setUserData] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const navigate = useNavigate();

    const toggle = () => setMenu((prevMenu) => !prevMenu);

    // Extract user details
    let username = "Admin";
    let userEmail = "";
    let userId = "";
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const uNm = obj?.username_127?.split("@")[0];
        userEmail = obj?.username_127;
        userId = obj?.id;
        username = uNm?.charAt(0)?.toUpperCase() + uNm?.slice(1);
    }

    useEffect(() => {
        getData();
    }, []);

    const handleView = (id) => {
        navigate(`/profile-view/${id}`);
      };

    const getData = async () => {
        try {
            const localData = localStorage.getItem("authUser");
            if (localData) {
                const parsedData = JSON.parse(localData);
                const payload = {
                    modelName: "vendor_masters",
                    whereCondition: {
                        id: parsedData.vendor_id_65,
                    },
                };

                const responseData = await getRecords("/common/getRecords", payload);
                if (responseData.data && responseData.data.length > 0) {
                    setUserData(responseData.data[0]);
                }
            } else {
                console.error("No authUser found in localStorage");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingData(false);
        }
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle
                    tag="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                >
                    <img
                        className="rounded-circle header-profile-user me-1"
                        src={user}
                        alt="Header Avatar"
                        style={{ width: "50px", height: "50px" }}
                    />
                    <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
                    <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-end"
                    style={{
                        minWidth: "260px",
                        overflow: "hidden",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <div
                        className="p-3"
                        style={{
                            borderBottom: "1px solid #ddd",
                            textAlign: "center",
                        }}
                    >
                        {loadingData ? (
                            <Spinner size="sm" color="primary" />
                        ) : (
                            <>
                                <h6 className="mb-1 text-primary">{username}</h6>
                                <p className="mb-0 text-muted">{userEmail}</p>
                                <small className="text-muted">{userData.vendor_name_14 || "Vendor Name"}</small>
                            </>
                        )}
                    </div>
                    <DropdownItem href="" className="mt-2" onClick={() => handleView(userId)}>
                        <i className="ri-user-line align-middle me-1"></i> Profile
                    </DropdownItem>
                    {/* 
                    <DropdownItem href="/settings">
                        <i className="ri-settings-2-line align-middle me-1"></i> Settings
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem
                        className="text-danger"
                        href="/logout"
                        // onClick={(e) => {
                        //     if (!window.confirm("Are you sure you want to logout?")) {
                        //         e.preventDefault();
                        //     }
                        // }}
                    >
                        <i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default withTranslation()(ProfileMenu);