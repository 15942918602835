import React, { useState } from "react";
import "./TextInput.css"
import CustomError from "../Errors/CustomError";


 const TextInput = ({
   label,
   name,
   value = "",
   onChange,
   parentClassName = "",
   inputClassName = "",
   labelClassName = "",
   placeholder = "Enter text",
   parentStyle = {},
   inputStyle = {},
   labelStyle = {},
   maxLength,
   minLength,
   readOnly = false,
   disabled = false,
   required = false,
   spellcheck = false,
   size,
   pattern,
   autocomplete = "off",
   customError,
 }) => {
   return (
     <>
       <div
         className={
           parentClassName && parentClassName.trim() !== ""
             ? parentClassName
             : "default-textinput-parent-class"
         }
         style={parentStyle}>
         <label
           style={labelStyle}
           className={
             labelClassName && labelClassName.trim() !== ""
               ? labelClassName
               : "default-textinput-label-class"
           }>
           {label}
         </label> {required && <span className="required-indicator">*</span>}
         <input
           name={name}
           type="text"
           value={value}
           onChange={onChange}
           placeholder={placeholder}
           className={
             inputClassName && inputClassName.trim() !== ""
               ? inputClassName
               : "default-textinput-input-class"
           }
           style={inputStyle}
           maxLength={maxLength}
           minLength={minLength}
           readOnly={readOnly}
           disabled={disabled}
           required={required}
           spellcheck={spellcheck}
           size={size}
           pattern={pattern}
           autocomplete={autocomplete}
         />
         {customError && <CustomError error={customError} />}
       </div>
     </>
   );
 };
export default TextInput
