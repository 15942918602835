import React, { useMemo, useCallback, useState, useEffect } from 'react';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Card, CardBody, Button, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import usePagination from '../../custom/Utils/UsePaginationUtils';
import { listPageData } from './PoHeadersFile';
import moment from 'moment/moment';
import { getRecords } from '../../services/api.service';
import * as XLSX from 'xlsx';
const PoHeadersList = () => {
    const navigate = useNavigate();
    const { payload, navigatePage, headerName } = listPageData();
    const initialPayload = useMemo(() => payload, []);
console.log('initialPayload', initialPayload);
    // pagination
   const {
       page,
       pageSize,
       nextPage,
       previousPage,
       lastPage,
       firstPage,
       handlePageSizeChange,
       setPage,
   } = usePagination();

    const url = '/common/get-records-by-column-filters';
     const [filters, setFilters] = useState({
         po_number_72: '',
         site_78: '',
         warehouse_name_21: '',
         buyer_group_81: '',
         po_status_125: '',
         po_status_microsoft_130: '',
         po_date_73: '',
         delivery_date_87: '',
         minimum_grn_date_94: '',
     });
     const [data, setData] = useState([]);
     const [isLoading, setIsLoading] = useState(true);
     const [totalCount, setTotalCount] = useState(0);

     const getData = useCallback(async () => {
         try {
             setIsLoading(true);
             let payload = {
                 ...initialPayload,
             };

                if (filters){
                    payload.search = Object.entries(filters)
                        .filter(([key, value]) => value.trim() !== '') 
                        .map(([key, value]) => ({
                            field_name:
                                key === 'warehouse_name_21'
                                    ? `warehouse_masters.${key}`
                                    : key === 'po_status_125'
                                    ? 'po_status.name'
                                    : key,
                            searchKeyword: value,
                        }));
                    } 
                       payload.pagination = {
                           page: page + 1,
                           pageSize: pageSize,
                       };
                    
                 

             console.log('payload initialPayload', initialPayload);
             console.log('payload...122', payload);

             const responseData = await getRecords(url, payload);
             if (responseData.code === 1) {
                 setTotalCount(responseData.count);
                 const sortedData = responseData.data
                     .map((element, index) => ({
                         ...element,
                         index: page * pageSize + (index + 1),
                     }))
                     .sort((a, b) => b.index - a.index);
                 setData(sortedData);
             }
         } catch (error) {
             console.error('Error fetching data:', error);
         } finally {
             setIsLoading(false);
         }
     }, [page, pageSize, filters, initialPayload]);

     useEffect(() => {
         getData();
     }, [getData]);
    
     const [focusedField, setFocusedField] = useState(null);
      const handleFocus = useCallback((fieldName) => {
          setFocusedField(fieldName);
      }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
        setPage(0);
    }, []);
    
const columns = useMemo(
    () => [
        {
            Header: 'Sr.No.',
            accessor: 'index',
            disableFilters: true,
            getHeaderProps: () => ({
                style: {
                    paddingBottom: '30px', // Add bottom padding to create white space below the header text
                },
            }),
        },
        {
            Header: 'PO Number',
            accessor: 'po_number_72',
            width: 120,
            Filter: () => (
                <input
                    type='text'
                    name='po_number_72'
                    value={filters.po_number_72}
                    onChange={handleInputChange}
                    placeholder='PO Number'
                    onFocus={() => handleFocus('po_number_72')}
                    autoFocus={focusedField === 'po_number_72'}
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            Header: 'PO Date',
            accessor: 'po_date_73',
            width: 90,
            Cell: ({ value }) => {
                const formattedDate = value
                    ? moment(value).format('DD-MM-YYYY')
                    : '';
                return <span>{formattedDate}</span>;
            },
            Filter: () => (
                <input
                    type='date'
                    name='po_date_73'
                    value={filters.po_date_73}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus('po_date_73')}
                    autoFocus={focusedField === 'po_date_73'}
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            Header: 'Site',
            accessor: 'site_78',
            width: 120,
            Filter: () => (
                <input
                    type='text'
                    name='site_78'
                    value={filters.site_78}
                    onChange={handleInputChange}
                    placeholder='site'
                    onFocus={() => handleFocus('site_78')}
                    autoFocus={focusedField === 'site_78'}
                    style={{ width: '140%' }}
                />
            ),
        },
        {
            Header: 'Warehouse',
            accessor: 'warehouse_masters.warehouse_name_21',
            Filter: () => (
                <input
                    type='text'
                    name='warehouse_name_21'
                    value={filters.warehouse_name_21}
                    onChange={handleInputChange}
                    placeholder='warehouse'
                    onFocus={() => handleFocus('warehouse_name_21')}
                    autoFocus={focusedField === 'warehouse_name_21'}
                    style={{ width: '110%' }}
                />
            ),
        },
        {
            Header: 'Buyer Group',
            accessor: 'buyer_group_81',
            Filter: () => (
                <input
                    type='text'
                    name='buyer_group_81'
                    value={filters.buyer_group_81}
                    onChange={handleInputChange}
                    placeholder='Buyer Group'
                    onFocus={() => handleFocus('buyer_group_81')}
                    autoFocus={focusedField === 'buyer_group_81'}
                    style={{ width: '110%' }}
                />
            ),
        },
        {
            Header: 'Delivery Date',
            accessor: 'delivery_date_87',
            Cell: ({ value }) => {
                const formattedDate = value
                    ? moment(value).format('DD-MM-YYYY')
                    : '';
                return <span>{formattedDate}</span>;
            },
            Filter: () => (
                <input
                    type='date'
                    name='delivery_date_87'
                    value={filters.delivery_date_87}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus('delivery_date_87')}
                    autoFocus={focusedField === 'delivery_date_87'}
                />
            ),
        },
        {
            Header: 'Min. GRN Date',
            accessor: 'minimum_grn_date_94',
            Cell: ({ value }) => {
                const formattedDate = value
                    ? moment(value).format('DD-MM-YYYY')
                    : '';
                return <span>{formattedDate}</span>;
            },
            Filter: () => (
                <input
                    type='date'
                    name='minimum_grn_date_94'
                    value={filters.minimum_grn_date_94}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus('minimum_grn_date_94')}
                    autoFocus={focusedField === 'minimum_grn_date_94'}
                />
            ),
        },
        {
            Header: 'PO Approval',
            accessor: 'po_status.name',
            Filter: () => (
                <input
                    type='text'
                    name='po_status_125'
                    value={filters.po_status_125}
                    onChange={handleInputChange}
                    placeholder='PO Approval'
                    onFocus={() => handleFocus('po_status_125')}
                    autoFocus={focusedField === 'po_status_125'}
                    style={{ width: '110%' }}
                />
            ),
        },
        {
            Header: 'PO Status',
            accessor: 'po_status_microsoft_130',
            Filter: () => (
                <input
                    type='text'
                    name='po_status_microsoft_130'
                    value={filters.po_status_microsoft_130}
                    onChange={handleInputChange}
                    placeholder='PO Status'
                    onFocus={() => handleFocus('po_status_microsoft_130')}
                    autoFocus={focusedField === 'po_status_microsoft_130'}
                    style={{ width: '110%' }}
                />
            ),
        },
        {
            Header: 'Action',
            accessor: 'actions',
            disableFilters: true,
            Cell: ({ row }) => (
                <Button
                    color='primary'
                    size='sm'
                    onClick={() => handleView(row.original.id)}
                >
                    <i className='fas fa-eye'></i>
                </Button>
            ),
        },
    ],
    [filters, handleInputChange]
);
    const handleView = useCallback(
        (id) => {
            navigate(navigatePage.view + '/' + id);
        },
        [navigate, navigatePage.view]
    );

    const handlePageSizeChangeWrapper = useCallback(
        (newSize) => {
            if (newSize !== pageSize) {
                handlePageSizeChange(newSize, totalCount);
                setPage(0); // Reset to first page when page size changes
            }
        },
        [handlePageSizeChange, pageSize, totalCount, setPage]
    );
    const exportToExcel = () => {
        const getNestedValue = (obj, path) => {
            return path
                .split('.')
                .reduce((value, key) => (value ? value[key] : ''), obj);
        };

        const headers = columns
            .filter((col) => col.accessor !== 'actions') // Exclude the 'Actions' column
            .map((col) => col.Header);
         const sortedData = [...data].sort((a, b) => b.id - a.id);
        const rows = sortedData.map((row) =>
            columns
                .filter((col) => col.accessor !== 'actions') // Exclude the 'Actions' column
                .map((col) => getNestedValue(row, col.accessor) || '')
        );

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Export the workbook as an Excel file
        XLSX.writeFile(wb, 'PO Headers.xlsx');
    };
    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <Breadcrumbs title={headerName} breadcrumbItems={[]} />
                        <Button
                            color='primary'
                            className='waves-effect waves-light me-1'
                            onClick={exportToExcel}
                            style={{ marginLeft: 'auto' }}
                        >
                            Export
                        </Button>
                    </div>

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={data || []}
                                isLoading={isLoading}
                                onSearchInputData={setFilters}
                                pageIndexProp={page}
                                onPageSizeChange={handlePageSizeChangeWrapper}
                                nextToPage={nextPage}
                                lastToPage={() => lastPage(totalCount)}
                                previousToPage={previousPage}
                                previousFirstToPage={firstPage}
                                totalCount={totalCount}
                                customPageSize={pageSize}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PoHeadersList;
