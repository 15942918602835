import React, { useState, useEffect, useMemo } from "react";
import { getRecords, dropdownData } from "../../services/api.service.js";
import { validate } from "../validations/Validate.js";

const useDependentDropdown = (dependentDropdownData, dependentValue, validateType, setSelectedDropOption, setFormErrors, setFormData, setLoadingData) => {
    const [selectedDropdown, setSelectedDropdown] = useState([]);
    const [selectedDropdownOptions, setSelectedDropdownOptions] = useState([]);
    const [selectedDropdownOptionsDependent, setSelectedDropdownOptionsDependent] = useState([]);
    const [selectedDropdownDependent, setSelectedDropdownDependent] = useState([]);

    const getDropdownData = async (id) => {
        if (!dependentDropdownData) {
            setLoadingData(false)
            return false;
        }
        let payload = dependentDropdownData[0]?.initialPayload;
        const responseData = await getRecords(payload);
        const dropdownResponseData = await dropdownData(responseData.data, dependentDropdownData[0]?.patchFieldName, dependentDropdownData[0]?.dropdownDataValue, dependentDropdownData[0]?.isOtherDependentOnThis,);

        if (id) {
            dropdownResponseData[0].options.forEach((element) => {
                if (element.value == id) {
                    setSelectedDropdown(element)
                }
            });
        }
        setSelectedDropdownOptions(dropdownResponseData);
    }

    const getDependentDropdownData = async (dependent_id, type) => {
        if (!dependentDropdownData[1]) {
            return false;
        }
        if (dependentDropdownData && dependentDropdownData.length > 0) {
            let payload = dependentDropdownData[1]?.initialPayload;
            if (payload && type === "dependent") {
                payload.whereCondition = { [dependentDropdownData[0]?.dropdownDataValue]: dependent_id };
            }
            const responseData = await getRecords(payload);
            const dropdownResponseData = await dropdownData(responseData.data, dependentDropdownData[1]?.patchFieldName, dependentDropdownData[1]?.dropdownDataValue, dependentDropdownData[1]?.isOtherDependentOnThis,);

            if (dependent_id) {
                dropdownResponseData[0].options.forEach((element) => {
                    if (element.value == dependent_id) {
                        setSelectedDropdownDependent(element);
                    }
                });
            }
            setSelectedDropdownOptionsDependent(dropdownResponseData);
        }
    }

    const handleSelectChange = (selectedOption) => {
        if (selectedOption?.value != dependentValue) {
            setSelectedDropdownDependent([])
        }

        // call api function
        if (selectedOption?.isOtherDependentOnThis) {
            getDependentDropdownData(selectedOption?.value, 'dependent');
            setSelectedDropdown(selectedOption);
        } else {
            setSelectedDropdownDependent(selectedOption);
        }

        const selectedValue = selectedOption ? selectedOption.value : "";
        const fieldError = validate(validateType, {
            [selectedOption?.fieldName]: selectedValue,
        });
        setSelectedDropOption(selectedOption);

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [selectedOption?.fieldName]: fieldError[selectedOption?.fieldName] || "",
        }));

        setFormData((prev) => ({
            ...prev,
            inputData: {
                ...prev.inputData,
                [selectedOption?.fieldName]: selectedOption ? selectedOption?.value : "",
            },
        }));
    };

    return { getDropdownData, getDependentDropdownData, handleSelectChange, selectedDropdown, selectedDropdownOptions, selectedDropdownDependent, selectedDropdownOptionsDependent }
}

export default useDependentDropdown;
